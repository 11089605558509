<template>
    <validation-observer ref="form" v-slot="{ invalid }" tag="div">
        <b-overlay :show="status === 'LOADING'">
            <b-form @submit.prevent="submit">
                <b-row>
                    <b-col cols="12">
                        <ckeditor-custom v-model="comment.comment" :content="comment.comment"/>
                    </b-col>
                    <b-col cols="12">
                        <custom-select
                            v-model="comment.status"
                            :name="$t('status')"
                            :options="commentStatusOptions"
                            required
                            rules="required"
                            vid="status"
                        />
                    </b-col>
                </b-row>
                <b-alert :show="errMessage !== null" class="p-2" dismissible variant="danger">
                    {{ errMessage }}
                </b-alert>
                <b-button :disabled="invalid" class="mt-2" type="submit" variant="primary">
                    {{ $t('save') }}
                </b-button>
            </b-form>
        </b-overlay>
    </validation-observer>
</template>

<script>
import { BAlert } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { commentStatusOptions } from "@/common/data";
import ckeditorCustom from "@/layouts/components/form/CKeditor";

export default {
    name: "Form",

    components: {
        ckeditorCustom,
        BAlert
    },

    props: {
        submit: {
            type: Function,
            required: true,
        },
        comment: {
            default() {
                return {
                    comment: '',
                    status: '',
                }
            },
            type: Object
        }
    },

    data() {
        return {
            errMessage: null,
            commentStatusOptions
        }
    },

    computed: {
        ...mapGetters('comment', [
            'status'
        ])
    }
}
</script>
