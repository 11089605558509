<template>
    <div>
        <b-button variant="info" class="mb-1" @click="showModal = !showModal">
            پاسخ
        </b-button>

        <b-card>
            <div class="">
                <div class="mb-1">
                    <span>نام:</span>
                    {{ comment.name ? comment.name : comment.user.name }}
                </div>
                <div class="mb-1">
                    <span>ایمیل:</span>
                    {{ comment.email ? comment.email : comment.user.email }}
                </div>
                <div class="mb-1">
                    <span>IP:</span>
                    {{ comment.ip_address }}
                </div>
                <div class="mb-1">
                    <span>زمان ثبت:</span>
                    {{ comment.created_at }}
                </div>
                <div class="mb-1">
                    <span>نوشته:</span>
                    <b-link :to="{name: 'edit-post', params:{id:comment.post.id}}">
                        {{ comment.post.title }}
                    </b-link>
                </div>
            </div>
            <Form ref="editComment" :comment="comment" :submit="submit"/>
        </b-card>

        <b-modal v-model="showModal" title="پاسخ به نظر" hide-footer size="lg">
            <Form ref="modalAnswer" :submit="submitAnswer"/>
        </b-modal>
    </div>
</template>

<script>
import Form from "./partials/Form";
import { mapActions, mapGetters } from "vuex";
import { BLink, BModal } from "bootstrap-vue";

export default {
    name: "EditComment",

    components: {
        Form,
        BModal,
        BLink
    },

    data() {
        return {
            showModal: false,
            id: this.$route.params.id
        }
    },

    computed: {
        ...mapGetters('comment', [
            'commentById'
        ]),
        comment() {
            const comment = this.commentById(this.id)

            if (!comment){
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.$router.push({name: 'comments'})
            }

            return comment
        }
    },

    methods: {
        ...mapActions({
            editComment: 'comment/edit',
            createComment: 'comment/create',
        }),
        submit() {
            const refComment = this.$refs.editComment
            refComment.errMessage = null

            const data = JSON.parse(JSON.stringify(refComment.comment))
            this.editComment(data).then(() => {
                // this.$router.push({ name: 'comments' })
                this.$swal({
                    icon: 'success',
                    title: this.$t(`Edited!`),
                    text: this.$t('Your file has been edited.'),
                    confirmButtonText: this.$t('Ok!'),
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })

            }).catch(response => {
                if (response.errors) {
                    refComment.$refs.form.setErrors(response.errors)
                }
                refComment.errMessage = response.message
            })
        },
        submitAnswer() {
            const refComment = this.$refs.modalAnswer
            refComment.errMessage = null

            const data = JSON.parse(JSON.stringify(refComment.comment))
            data.parent_id = this.id
            this.createComment(data).then(() => {
                // this.$router.push({ name: 'comments' })
                this.$swal({
                    icon: 'success',
                    title: this.$t(`Edited!`),
                    text: this.$t('Your file has been edited.'),
                    confirmButtonText: this.$t('Ok!'),
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })

            }).catch(response => {
                if (response.errors) {
                    refComment.$refs.form.setErrors(response.errors)
                }
                refComment.errMessage = response.message
            })
        }
    }
}
</script>
